import React from "react";
import "./styles.css";

export default function Link(props) {
  return (
    <div>
      <a href={props.linkRoute} className="__link" data-testid="kb-link">
        {props.linkText}
      </a>
    </div>
  );
}
