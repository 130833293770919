import React from 'react'
import './styles.css'

export default function PageTitle(props) {
    return (
        <>
            <h1
                className="__page-title"
                data-testid="page-title"
            >{props.title}</h1>
        </>
    )
}
