import React from "react";
import NavigationRoutes from "../navigation";
import { withFirebase } from "../Firebase";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null
    };
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
  }

  render() {
    return (
      <div>
        <NavigationRoutes authUser={this.state.authUser} />
      </div>
    );
  }
}

export default withFirebase(App);
