import React from "react";
import "./styles.css";

export default function KanbanColumn(props) {
    return (
        <>
            <div className="__book-header" data-testid="book-column">
                <div className="__book-header-count" data-testid="book-count">{props.bookCount}</div>
                <div className="__book-header-name" data-testid="column-name">{props.columnName}</div>
            </div>

        </>
    );
}
