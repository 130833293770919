import React from "react";
import KanbanColumn from "../components/kanban-column";
import PageTitle from "../components/page-title";
import UserSettingsButton from "../components/user-settings-button";
import * as ROUTES from "../constants/routes.js";
import {Colors} from "../util/colors.js"

const columnHeaderData = [
      ["77", "To Read"],["1", "Reading"],["13", "Read"]
  ];

class BookBoard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  renderColumnHeader = (totalBooks, columnTitle) => {
    const getTestId = columnTitle.toLowerCase().split(' ').join('_');
    return (
        <KanbanColumn
            bookCount={`${totalBooks}`}
            columnName={`${columnTitle}`}
            id={`${getTestId}`}
            key={`${getTestId}`}
        />
    )
  };

  render() {
    return (
      <div>
        <div><UserSettingsButton id="user-cog-button" href={ROUTES.MY_ACCOUNT} /></div>
        {columnHeaderData.map(columnHeader => this.renderColumnHeader(...columnHeader))}
        <hr style={{color: Colors.OliveGreen}}/>
        <PageTitle title="Kanban Board" />
      </div>
    );
  }
}

export default BookBoard;
