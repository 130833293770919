import React from "react";
import AltPageTitle from "../../components/alt-page-title";
import AltLink from "../../components/alt-link";
import BackButton from "../../components/back-button";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes.js";
import {Colors} from "../../util/colors";
import "./styles.css";

class MyAccountBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserEmail: '',
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = Colors.PeatBrown;
    this.getCurrentUserEmail();
  }

  onClick = event => {
    this.props.firebase.doSignOut();
  };

  getCurrentUserEmail = () => {
    const emailFromDB = this.props.firebase.auth.currentUser.email;
    this.setState({
      currentUserEmail: emailFromDB
    })
  };

  render() {
    const { currentUserEmail } = this.state;

    return (
      <div>
        <AltPageTitle title="My Account"/>
        <div className="__user-email-display">{currentUserEmail}</div>
        <AltLink linkRoute={ROUTES.PASSWORD_RESET} linkText="Password Reset" />
        <AltLink
          linkRoute={ROUTES.LOGIN}
          linkText="Sign out"
          onClick={this.onClick}
        />
      </div>
    );
  }
}

const MyAccountMenu = withFirebase(MyAccountBase);

const MyAccountPage = () => (
  <div>
    <BackButton href={ROUTES.BOOK_BOARD} />
    <MyAccountMenu />
  </div>
);

export default MyAccountPage;
