import React from "react";
import "./styles.css";

export default function ErrorMessage(props) {
  return (
    <div>
      <p className="__error-message" data-testid="error-message">
        ⚠ {props.errorMsg}
      </p>
    </div>
  );
}
