import React from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Login from "../../pages/Login.js";
import SignUp from "../../pages/SignUp.js";
import ForgotPassword from "../../pages/ForgotPassword.js";
import BookBoard from "../../pages/BookBoard.js";
import MyAccount from "../../pages/MyAccount/MyAccount.jsx";
import PasswordReset from "../../pages/PasswordReset.js";
import * as ROUTES from "../../constants/routes";

export default function NavigationRoutes(props) {
  const Navigation = ({ authUser = props.authUser }) => (
    <div>
      {authUser ? <NavigationAuth /> : <NavigationNonAuth />}{" "}
      <Route
        exact
        path={ROUTES.LANDING}
        render={() =>
          authUser ? (
            <Redirect to={ROUTES.BOOK_BOARD} />
          ) : (
            <Redirect to={ROUTES.LOGIN} />
          )
        }
      />
    </div>
  );

  const NavigationAuth = () => (
    <div>
      <Route path={ROUTES.BOOK_BOARD} component={BookBoard} />
      <Route path={ROUTES.MY_ACCOUNT} component={MyAccount} />
      <Route path={ROUTES.PASSWORD_RESET} component={PasswordReset} />
    </div>
  );

  const NavigationNonAuth = () => (
    <div>
      <Route path={ROUTES.LOGIN} component={Login} />
      <Route path={ROUTES.SIGN_UP} component={SignUp} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
    </div>
  );

  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}
