import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import PageTitle from "../components/page-title";
import TextInput from "../components/text-input";
import ButtonPrimary from "../components/button-primary";
import Link from "../components/link";
import ErrorMessage from "../components/error-message";
import { withFirebase } from "../components/Firebase";
import * as ROUTES from "../constants/routes.js";

const SignUpPage = () => (
  <div>
    <PageTitle title="Sign Up" />
    <div style={{ marginBottom: "60px" }}></div>
    <SignUpForm />
    <div style={{ marginBottom: "40px" }}></div>
    <Link linkRoute={ROUTES.LOGIN} linkText="Log In" />
    <Link linkRoute={ROUTES.FORGOT_PASSWORD} linkText="Forgot your password?" />
  </div>
);

const INITIAL_STATE = {
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class SignUpFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, passwordOne } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
       this.props.firebase
          .user(authUser.user.uid)
          .set({
            email: email,
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.BOOK_BOARD);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <TextInput
          onChange={this.onChange}
          id="signup-email"
          labelName="Email"
          name="email"
          type="email"
          value={email}
        />
        <TextInput
          id="signup-passwordOne"
          labelName="Password"
          name="passwordOne"
          onChange={this.onChange}
          type="password"
          value={passwordOne}
        />
        <TextInput
          onChange={this.onChange}
          id="signup-passwordTwo"
          labelName="Confirm Password"
          name="passwordTwo"
          type="password"
          value={passwordTwo}
        />
        <ButtonPrimary
          disabled={isInvalid}
          buttonType="submit"
          buttonName="Create Account"
        />
        {error && <ErrorMessage errorMsg={error.message} />}
      </form>
    );
  }
}

const SignUpForm = compose(
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;
