import React from "react";
import "./styles.css";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UserSettingsButton(props) {
  return (
    <>
      <a data-testid="user-settings-link" href={props.href}>
        <FontAwesomeIcon
          icon={faUserCog}
          data-testid="user-settings-button"
          className="__user-settings-button"
        />
      </a>
    </>
  );
}
