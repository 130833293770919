import React from "react";
import "./styles.css";

export default function AltPageTitle(props) {
  return (
    <div>
      <h1 className="__alt-page-title" data-testid="alt-page-title">
        {props.title}
      </h1>
    </div>
  );
}
