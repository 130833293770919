import React from "react";
import "./styles.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BackButton(props) {
  return (
    <>
      <a data-testid="back-button-link" href={props.href}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          data-testid="back-button"
          className="__back-button"
        />
      </a>
    </>
  );
}
