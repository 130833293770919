import React from "react";
import "./styles.css";

export default function ButtonPrimary(props) {
  return (
    <div>
      <button
        disabled={props.disabled}
        type={props.buttonType}
        className="__button-primary"
        data-testid="button"
      >
        {props.buttonName}
      </button>
    </div>
  );
}
