import React from "react";
import PageTitle from "../components/page-title";
import TextInput from "../components/text-input";
import ButtonPrimary from "../components/button-primary";
import Link from "../components/link";
import ErrorMessage from "../components/error-message";
import { withFirebase } from "../components/Firebase";

const ForgotPasswordPage = () => (
  <div>
    <PageTitle title="Password Reset" />
    <div style={{ marginBottom: "225px" }}></div>
    <ForgotPasswordForm />
    <div style={{ marginBottom: "40px" }}></div>
    <Link linkRoute="/login" linkText="Back to Login" />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class ForgotPasswordFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <TextInput
          labelName="Email"
          name="email"
          type="email"
          id="forgot-password-email"
          value={this.state.email}
          onChange={this.onChange}
        />

        <ButtonPrimary disabled={isInvalid} buttonName="Send Reset Request" />
        {error && <ErrorMessage errorMsg={error.message} />}
      </form>
    );
  }
}

const ForgotPasswordForm = withFirebase(ForgotPasswordFormBase);

export default ForgotPasswordPage;
