import React from 'react'
import './styles.css'

export default function TextInput(props) {
    return (
        <>
            <label
                className="__input-label"
                data-testid="text-input-label"
            >
                {props.labelName}
            </label>
            <input
                name={props.name}
                type={props.type}
                value={props.value}
                id={props.id}
                className="__text-input"
                placeholder={props.placeholder}
                onChange={props.onChange}
                data-testid="text-input"
            />
        </>
    )
}
