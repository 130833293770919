import React from "react";
import "./styles.css";

export default function AltLink(props) {
  return (
    <div>
      <a
        href={props.linkRoute}
        className="__alt-link"
        data-testid="alt-kb-link"
        onClick={props.onClick}
      >
        {props.linkText}
      </a>
    </div>
  );
}
