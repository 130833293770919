import React from "react";
import AltPageTitle from "../components/alt-page-title";
import BackButton from "../components/back-button";
import TextInput from "../components/text-input";
import ButtonPrimary from "../components/button-primary";
import ErrorMessage from "../components/error-message";
import { withFirebase } from "../components/Firebase";
import * as ROUTES from "../constants/routes.js";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#756d54";
  }

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <form onSubmit={this.onSubmit}>
        <BackButton href={ROUTES.MY_ACCOUNT} />
        <AltPageTitle title="Password Reset" />
        <div style={{ marginBottom: "125px" }}></div>
        <TextInput
          name="passwordOne"
          type="password"
          id="passwordOne-input"
          value={passwordOne}
          onChange={this.onChange}
          placeholder="New Password"
        />
        <div style={{ marginBottom: "50px" }}></div>
        <TextInput
          name="passwordTwo"
          type="password"
          id="passwordTwo-input"
          value={passwordTwo}
          onChange={this.onChange}
          placeholder="Confirm New Password"
        />
        <div style={{ marginBottom: "50px" }}></div>
        <ButtonPrimary
          disabled={isInvalid}
          buttonType="submit"
          buttonName="Reset Password"
        />
        {error && <ErrorMessage errorMsg={error.message} />}
      </form>
    );
  }
}

export default withFirebase(PasswordResetForm);
